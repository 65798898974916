import config from '../config';

const generatePlaylistPrompt = (selectedMoods, selectedArtists) => {
    return `Generate a JSON object representing a playlist based on the following criteria:

    1. User's current emotions: ${selectedMoods.join(', ')}
    2. User's selected artists: ${selectedArtists.join(', ')}

    RULES:
    - Create a playlist of exactly 25 songs.
    - Each song should match the user's selected mood(s).
    - Lyrics should correlate with the mood/vibe.
    - Include songs from artists similar to the user's preferences but dont use the same artist more than 3 times (maximum).
    - Ensure the sound of each song aligns with the selected mood(s).
    - Give unique answers
    - NO explanatory text before or after the JSON

    The JSON object should follow this schema:
    {
      "playlist": [
        {
          "artist": "string",
          "song": "string",
          "id": "string"
        }
      ]
    }

    Provide the complete JSON object with 25 songs, ensuring all artist and song fields including song id taken from Spotify are filled.`;
};

export const generatePlaylist = async (selectedMoods, selectedArtists) => {
    try {
        const response = await fetch('https://api.groq.com/openai/v1/chat/completions', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${config.API_KEY}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                model: "mixtral-8x7b-32768",
                messages: [{
                    role: "user",
                    content: generatePlaylistPrompt(selectedMoods, selectedArtists)
                }],
                temperature: 0.5,
                max_tokens: 4096
            })
        });

        if (!response.ok) {
            throw new Error('Failed to generate playlist');
        }

        const data = await response.json();
        return JSON.parse(data.choices[0].message.content);
    } catch (error) {
        console.error('Error generating playlist:', error);
        throw error;
    }
};